(function ($) {

    $.fn.extend({
        language: function (options) {
            var self = this;

            var defaults = {
                defaultLanguage: 'English'
            };

            var opts = $.extend({ }, defaults, options);
                      
            var languages = [];

            //var anyHasValue = false;

            $('[data-language]').each(function (index, element) {
                element = $(element);

                var language = element.data('language');

                var contains = false;
                for (var i = 0; i < languages.length; i++) {
                    if (languages[i] == language) {
                        contains = true;
                        break;
                    }
                }
                if (!contains)
                {
                    languages.push(language);
                }
            });
            
            var languageGroup = $('<div>').attr('data-toggle', 'buttons').addClass('btn-group');

            for (var i = 0; i < languages.length; i++) {
                var button = $('<span>').addClass('btn btn-default').html(languages[i]);
                var attributeSearch = '[data-language="' + languages[i] + '"]';
                if (languages[i] == opts.defaultLanguage) {
                    button.button('toggle');
                }
                else {

                    $(attributeSearch).each(function (subIndex, subElement) {
                        $(subElement).hide();
                    });
                }
                button.click(attributeSearch, function (event) {
                    $('[data-language]').each(function(index, element) {
                        element = $(element);                        
                        element.hide();
                    });

                    $(event.data).each(function (subIndex, subElement) {
                        $(subElement).toggle();
                    });
                });
                languageGroup.append(button);
            }
            $(self).append(languageGroup);

            languageGroup.button();
        }
    });

}(jQuery));


// adds .naturalWidth() and .naturalHeight() methods to jQuery
// for retreaving a normalized naturalWidth and naturalHeight.
(function ($) {
    var
    props = ['Width', 'Height'],
    prop;

    while (prop = props.pop()) {
        (function (natural, prop) {
            $.fn[natural] = (natural in new Image()) ?
            function () {
                return this[0][natural];
            } :
            function () {
                var
                node = this[0],
                img,
                value;

                if (node.tagName.toLowerCase() === 'img') {
                    img = new Image();
                    img.src = node.src,
                    value = img[prop];
                }
                return value;
            };
        }('natural' + prop, prop.toLowerCase()));
    }
}(jQuery));


(function ($) {

    // jQuery autoGrowInput plugin by James Padolsey
    // See related thread: http://stackoverflow.com/questions/931207/is-there-a-jquery-autogrow-plugin-for-text-fields

    $.fn.autoGrowInput = function (o) {

        o = $.extend({
            maxWidth: 1000,
            minWidth: 0,
            comfortZone: 70
        }, o);

        this.filter('input:text').each(function () {

            var minWidth = o.minWidth || $(this).width(),
                val = '',
                input = $(this),
                testSubject = $('<tester/>').css({
                    position: 'absolute',
                    top: -9999,
                    left: -9999,
                    width: 'auto',
                    fontSize: input.css('fontSize'),
                    fontFamily: input.css('fontFamily'),
                    fontWeight: input.css('fontWeight'),
                    letterSpacing: input.css('letterSpacing'),
                    whiteSpace: 'nowrap'
                }),
                check = function () {

                    if (val === (val = input.val())) { return; }

                    // Enter new content into testSubject
                    var escaped = val.replace(/&/g, '&amp;').replace(/\s/g, '&nbsp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
                    testSubject.html(escaped);

                    // Calculate new width + whether to change
                    var testerWidth = testSubject.width(),
                        newWidth = (testerWidth + o.comfortZone) >= minWidth ? testerWidth + o.comfortZone : minWidth,
                        currentWidth = input.width(),
                        isValidWidthChange = (newWidth < currentWidth && newWidth >= minWidth)
                                             || (newWidth > minWidth && newWidth < o.maxWidth);

                    // Animate width
                    if (isValidWidthChange) {
                        input.width(newWidth);
                    }

                };

            testSubject.insertAfter(input);

            $(this).bind('keyup keydown blur update', check);

        });

        return this;

    };

})(jQuery);